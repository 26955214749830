import BaseError from 'lib/errors/BaseError'
import { humanizeSyftApiCallError } from 'lib/syftApiErrors'
import { datadogRum } from '@datadog/browser-rum-slim'

type ErrorMeta = {
  /**
   * Request URL
   */
  url: string
  /**
   * See Response.status
   */
  status?: number
  /**
   * See Response.statusText
   */
  statusText?: string
  /**
   * X-Request-Id header value
   */
  requestId?: string
}

export type ApiError = {
  /**
   * object with deserialized response body
   */
  body?: Record<string, any>
  /**
   * Fetch Response
   */
  response?: Response
  /**
   * Error from JSON.parse()
   */
  parseError?: Error
  /**
   * Error from fetch()
   */
  fetchError?: unknown
  /**
   * Response Meta object
   */
  meta?: ErrorMeta
}

export default class ApiCallError extends BaseError {
  public body?: ApiError['body']
  public response?: ApiError['response']
  public jsonError?: ApiError['parseError']
  public fetchError?: ApiError['fetchError']
  public meta?: ApiError['meta']

  name = 'ApiCallError'

  constructor(error?: ApiError) {
    super(humanizeSyftApiCallError(error || {}, {}) || '')

    Object.assign(this, error)

    const origErrorCause = error?.parseError ?? error?.fetchError
    const errorCause =
      origErrorCause instanceof Error
        ? origErrorCause
        : origErrorCause
        ? new Error(`Non Error exception "${origErrorCause}"`)
        : undefined
    this.cause = errorCause
    this.context = error || {}

    // log to DataDog only those errors that are not automatically logged by DataDog
    // Only 200 or 404 responses are OK responses, rest considered 'bad'
    // Fetch errors are those resource calls that have "0" status code (like CORS, Abort etc errors)
    // if its fetch error (caught error from await fetch()), they are Error instances (either Type Error or Abort)
    // thus we're not interested in logging them (already logged)
    if (
      errorCause?.name !== 'AbortError' &&
      (error?.parseError || (error?.fetchError && !(error.fetchError instanceof Error)))
    ) {
      datadogRum.addError(errorCause, error)
    }
  }
}
