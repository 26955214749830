import { put, call, all } from 'redux-saga/effects'
import { forEach, compact, every } from 'lodash'

import * as types from 'actions/action-types'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityActionRequest } from 'lib/redux-crud/entityActionRequest'
import { putFailureAction } from './errorMessage'

const getAgencyShiftPayload = ({ workersRequired, agencyShiftId, shiftId, listingId, agencyId }) => ({
  agencyShiftId,
  entityType: 'agencyShift',
  workersBooked: workersRequired,
  shiftId,
  listingId,
  agencyId,
})

const agencyShiftApiRequest = function* ({
  areAllShiftsRequiringZeroWorkers,
  manageAgencyAllocation,
  disableWholeDeletion,
  acceptAllocations,
  listingId,
  agencyId,
  deleteAgencyShiftAction,
  createAgencyShiftAction,
  updateAgencyShiftAction,
}) {
  if (
    areAllShiftsRequiringZeroWorkers &&
    !manageAgencyAllocation &&
    !disableWholeDeletion &&
    !acceptAllocations
  ) {
    yield call(entityActionRequest, {
      type: types.AGENCY_SHIFT_DELETE_BEGIN,
      payload: { listingId, agencyId },
      meta: { deleteWholeAllocation: true },
    })
  } else {
    const response = yield all(
      compact(
        [deleteAgencyShiftAction, createAgencyShiftAction, updateAgencyShiftAction].map(action => {
          if (action?.payload?.entries?.length || action?.payload?.agencyShifts?.length) {
            return call(entityActionRequest, {
              ...action,
              meta: { ...action.meta, manageAgencyAllocation, acceptAllocations },
            })
          }
          return null
        }),
      ),
    )
    if (response?.[0]?.type === types.AGENCY_SHIFT_UPDATE_FAILED && acceptAllocations) {
      yield putFailureAction({
        type: types.LISTING_AGENCY_SHIFTS_CREATE_FAILED,
        error: response[0].payload,
        requestOptions: { entity: response[0].payload },
        beginAction: response[0],
      })
    }
  }
}

/**
 * We recieve a list of shifts that need to individually call APIs
 * if there is a difference from inital value
 *
 * Assumption: if number of inital workers for shift is 0,
 * we assume the agency shift is not created on the BE
 * @param {*} payload
 * @param {*} options
 */
export function* submitAgencyListing(options = {}) {
  yield takeLatestDeep(types.LISTING_AGENCY_SHIFTS_CREATE_BEGIN, function* (action) {
    // TODO: need to update `allow individual booking` bool
    const { shifts, listingId, agencyId } = action.payload
    const { manageAgencyAllocation, disableWholeDeletion, acceptAllocations, removeAllRelatedJobs } =
      action.meta
    const deleteAgencyShiftAction = {
      type: types.AGENCY_SHIFT_DELETE_BEGIN,
      payload: { entries: [], removeAllRelatedJobs },
    }

    const createAgencyShiftAction = {
      type: types.AGENCY_SHIFT_CREATE_BEGIN,
      payload: { entries: [] },
    }

    const updateAgencyShiftAction = {
      type: types.AGENCY_SHIFT_UPDATE_BEGIN,
      payload: acceptAllocations
        ? { agencyShifts: [], removeAllRelatedJobs }
        : { entries: [], removeAllRelatedJobs },
    }

    forEach(shifts, (shift, key) => {
      // when managing agency from manage agency dialog, shifts is an array of shifts with shiftId in it
      // otherwise it is an object with the shiftId as a key
      // shiftId must be always present in shift object, but for the sake of safety, taking shiftId from shift object only when its coming from FieldArray
      const shiftId = Array.isArray(shifts) ? shift.shiftId || shift.agencyShiftId : parseInt(key, 10)
      const { workersRequired, agencyShiftId } = shift

      const payload = getAgencyShiftPayload({
        workersRequired,
        agencyShiftId,
        shiftId,
        listingId,
        agencyId,
      })
      if (acceptAllocations) {
        updateAgencyShiftAction.payload.agencyShifts.push({ agencyShiftId, workersAccepted: workersRequired })
      } else if (workersRequired === 0 && agencyShiftId) {
        deleteAgencyShiftAction.payload.entries.push(payload)
      } else if (!agencyShiftId && workersRequired > 0) {
        createAgencyShiftAction.payload.entries.push(payload)
      } else if (workersRequired > 0) {
        updateAgencyShiftAction.payload.entries.push(payload)
      }
    })
    const areAllShiftsRequiringZeroWorkers = every(shifts, ({ workersRequired }) => workersRequired === 0)

    try {
      yield call(agencyShiftApiRequest, {
        areAllShiftsRequiringZeroWorkers,
        manageAgencyAllocation,
        disableWholeDeletion,
        acceptAllocations,
        listingId,
        agencyId,
        deleteAgencyShiftAction,
        createAgencyShiftAction,
        updateAgencyShiftAction,
        removeAllRelatedJobs,
      })
      yield put({
        type: types.LISTING_AGENCY_SHIFTS_CREATE_SUCCEEDED,
        payload: { listingId, agencyId, manageAgencyAllocation, areAllShiftsRequiringZeroWorkers },
      })
    } catch (error) {
      yield putFailureAction({
        type: types.LISTING_AGENCY_SHIFTS_CREATE_FAILED,
        error,
        requestOptions: { entity: action.payload },
        beginAction: action,
      })
    }
  })
}
