export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    line_1: {
      type: 'string',
      description: 'Address',
    },
    line_2: {
      type: 'string',
      description: 'Address line 2',
    },
    city: {
      type: 'string',
    },
    post_code: {
      type: 'string',
    },
    federated_state_code: {
      type: 'string',
      description: 'US State code',
    },
    latitude: {
      type: 'number',
      description: 'Latitude',
    },
    longitude: {
      type: 'number',
      description: 'Longitude',
    },
  },
}
