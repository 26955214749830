import { countryCodes } from 'common/constants'
import config from 'config'
import * as FileSaver from 'file-saver-es'

import ApiCallError from 'lib/errors/ApiCallError'
import { find, get, isEmpty, isPlainObject, pick } from 'lodash'
import { saveOauthDataToLocalStorage } from '../utils/oauth'
import { deserializeResponse } from './deserialization'
import { apiURL } from './endpoints'
import { jsonBody } from './params'

// TODO Refactor
// Oauth2 token information.
let oauthData = null

// TODO Refactor
// The headers sent for oauth authentication and platform identification.
let syftApiHeaders = {}

const defaultCountryCode = config.IS_FLEX ? countryCodes.US : countryCodes.GB

/**
 * Sets authentication credentials. If oauth details are provided,
 * the authorization headers are generated as well.
 *
 * TODO Refactor to pull auth data from the current redux state
 *
 * @param oauth Oauth data
 */
export const updateAuth = (oauth, user, isAdmin) => {
  oauthData = oauth

  syftApiHeaders = {}

  if (oauth != null && !isEmpty(oauth)) {
    const tokenType = oauthData.tokenType
    const accessToken = oauthData.accessToken
    syftApiHeaders.Authorization = `${tokenType} ${accessToken}`
    // TODO Refactor
    syftApiHeaders['X-Platform-Id'] = get(user, 'platform.id') || syftApiHeaders['platform.id'] || '1'
    syftApiHeaders['X-Country-Code'] =
      get(user, 'countryCode') || syftApiHeaders['X-Country-Code'] || defaultCountryCode
  }

  saveOauthDataToLocalStorage(oauth)
}

/**
 * Retrieves a header field value from a Fetch API response.
 *
 * @param {Response} response
 * @param {String} headerName Name we're looking for
 * @param {Object} options e.g. isInteger
 */
export const parseHeader = (response, headerName, { isInteger } = {}) => {
  const headers = response.headers
  const value = headers && headers.get(headerName)
  return isInteger ? value && parseInt(value, 10) : value
}

// Make a friendly error message for ApiCallError
//
// @param {ApiCallError} error
// @return {String?}
export const humanizeApiCallError = error => {
  if (error.status === 500) {
    // See WEB2-528
    return 'Sorry, we may be experiencing high server load. Please try again in a few minutes.'
  } else if (error.fetchError) {
    return 'Sorry, there was a problem loading data. Please check your internet connection.'
  }
}

/**
 * Handles a single API call according to the default procedure,
 * which is to check for the HTTP status code, and then either reject
 * with a standard error message, or resolve with the unpacked JSON data
 * if the status is 200.
 *
 * @param {RequestInfo} req API request
 * @param resolve {Function (payload, response, meta ->)} Resolution handler
 * @param reject {Function (ApiCallError ->)} Rejection handler.
 * @param pagination Whether we expect to see pagination data in the response
 */
export const handleCall = async (req, resolve, reject, pagination = false, toCsv, csvName) => {
  const requestMeta = { url: req.url }
  try {
    const response = await fetch(req)
    const responseMeta = {
      ...requestMeta,
      ...pick(response, ['status', 'statusText']),
      requestId: parseHeader(response, 'X-Request-Id'),
    }
    if (toCsv) {
      const body = await response.text()
      const csvText = new Blob([body], { type: 'text/csv' })
      return FileSaver.saveAs(
        csvText,
        response.headers.get('Content-Disposition')
          ? // eslint-disable-next-line no-useless-escape
            response.headers.get('Content-Disposition').match(/\"(.*?)\"/)[1]
          : `${csvName}.csv`,
      )
    }
    try {
      let body
      let isJSONBody = false
      const contentType = response.headers.get('content-type')

      // sometimes BE returns a text/plain response when it should be json, like in case of 500 (not OK) or when Cloudflare is blocking the request
      if (contentType && contentType.includes('application/json')) {
        body = await response.json()
        isJSONBody = true
      } else {
        // if not JSON, lets try to parse it as text so we can log whatever is there
        body = await response.text()
      }

      // Reject if we have any status code other than 200 or 404.
      if (response.ok) {
        if (!isJSONBody) {
          reject(
            new ApiCallError({
              body,
              response,
              parseError: new Error('Response is not of expected JSON type'),
              meta: responseMeta,
            }),
          )
        } else {
          const deserializedBody = body ? deserializeResponse(body) : null
          resolve({ payload: deserializedBody, response, meta: responseMeta })
        }
      } else {
        // All failure responses from Syft API should contain a meaningful error message
        reject(
          new ApiCallError({
            body: isJSONBody ? deserializeResponse(body) : body,
            response,
            meta: responseMeta,
          }),
        )
      }
    } catch (error) {
      // We're unlikely to end up here – all responses from Syft API should be
      // of JSON type
      reject(
        new ApiCallError({
          response,
          parseError: error,
          meta: responseMeta,
        }),
      )
    }
  } catch (error) {
    // Error in Internet connection. Like DISCONNECT
    reject(
      new ApiCallError({
        fetchError: error,
        meta: requestMeta,
      }),
    )
  }
}

// List of endpoints that are auto forwarded by Syft Gateway
// to the correct backend instance based on platform
//
// All other endpoints require platform identification (X-Platform-Id)
// in the headers.
//
// https://syftapp.atlassian.net/wiki/spaces/SV/pages/61177860/Platforms
const autoforwardedEndpoints = [
  { method: 'POST', path: '/users/login' },
  { method: 'POST', path: '/users/registration' },
  { method: 'POST', path: '/users/recover_password' },
  { method: 'POST', path: '/users/accept_invitation' },
]

/**
 * Returns the default request parameters.
 *
 * @returns {Object} Request object parameters
 */
export const requestDefaults = ({ method = 'GET', contentType, path }) => {
  const isAutoforwarded = !!find(autoforwardedEndpoints, { method, path })
  const apiHeaders = isAutoforwarded ? {} : syftApiHeaders
  const deviceId = config.ctk

  const headers = {
    Accept: 'application/json, */*',
    // https://syftapp.atlassian.net/wiki/spaces/SV/pages/10944518/App+versioning+forced+upgrade
    'X-Client-Version': `SyftApp-Web ${config.releaseVersion?.split('-')[0] || '0.0.0'}; `,
    ...apiHeaders,
  }
  // https://syftapp.atlassian.net/wiki/spaces/SV/pages/146112513/Review+Apps+API
  if (contentType) {
    headers['Content-Type'] = contentType
  }

  if (deviceId) {
    headers['X-Device-Id'] = deviceId
  }

  return {
    method,
    headers,
    cache: 'default',
    referrerPolicy: 'no-referrer-when-downgrade',
  }
}

/**
 * Returns a single request for one API call.
 *
 * @param path API path to make a call to
 * @param body Key/value pairs or a FormData instance to be sent in the body or query string
 * @param headers User request headers
 * @param method HTTP method to use for this call, defaults to GET
 * @param query URL params' object
 * @returns {Request} New request for making the desired API call
 */
export function apiRequest({ path, body = {}, headers = {}, method = 'GET', query: requestQuery = {} }) {
  let serializedBody = null
  let query = {
    ...requestQuery,
  }
  let contentType = ''

  // GET requests are always sent as a plain query string.
  if (method === 'GET') {
    query = body || {}
    contentType = 'application/x-www-form-urlencoded'
  }
  // For all other methods, we send JSON in case we're using a plain key/value object as data.
  else if (isPlainObject(body)) {
    serializedBody = jsonBody(body)
    contentType = 'application/json'
  }
  // If we're sending FormData, the Content-Type header will be set automatically.
  else {
    serializedBody = body
    contentType = false
  }

  const defaults = requestDefaults({ method, contentType, path })

  return new Request(apiURL(path, query), {
    ...defaults,
    headers: { ...defaults.headers, ...headers },
    body: serializedBody,
  })
}
